body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(45deg, #3d2b5a 0%, #472b74 50%, #7143bd 85%,#7f41e2 100%);
  font-size: 20px;
}
